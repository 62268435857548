$primary: #EC2027;

@import '~bootstrap/scss/bootstrap';

.btn-outline-primary:active,
.btn-outline-primary:hover {
  color: white !important;
}

h1, h2 {
  margin: 0;
  font-family: "museo", serif;
  font-style: normal;
  font-weight: 500;
  color: white;
  background-color: $primary;
}

h1 {
  font-weight: 700;
}

.hero-title {
  background-color: $primary;
}

.team-year{
  margin: 0;
  font-family: "museo", serif;
  font-style: normal;
  font-weight: 500;
  color: $primary;
  background-color: "transparent";
}

.impact-line {
  background-color: $primary;
}


body, h3, h4, h5 p {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue LT Pro', sans-serif;
  font-weight: 45 light;
}

h3 {
  font-weight: 75 bold;
}

h4, h5 {
  font-weight: 55;
}

.text-primary {
  font-weight: 75;
  color: $primary;
}


.header-link {
  color: white !important;
  transition: color 0.3s ease-in-out; /* Add transition for smooth color change */
}

.header-link:hover,
.header-link.active {
  color: $primary !important;
}

.footer-copy {
  margin-top: 10px;
}

.footer-social-link {
  color: white;
  margin: 0 10px;
  transition: color 0.3s ease-in-out;
}

.footer-social-link:hover {
  color: $primary;
}

.App {
  text-align: center;
}

.page-container {
  padding-bottom: 1em;
  margin-top: 1em;
}

a {
  color: $primary;
}

.navbar {
  background-color: black!important; /* Override Bootstrap navbar background color to black */
}

#root > div > footer {
  background-color: black !important; /* Set the background color of the footer to black */
}

.hidden-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}
